body.page-product {
  @if $fonts_update == false {
    font-family: $roboto-mono_regular-font !important;
    font-size: 1rem !important;
  }
  #colorbox {
    #cboxWrapper {
      #cboxContent {
        #cboxLoadedContent {
          background-color: white;
        }
      }
    }
    &.welcome15-overlay {
      #cboxWrapper {
        #cboxContent {
          #cboxLoadedContent {
            background-color: black;
          }
        }
      }
    }
  }
}

.section-stores {
  .locations_map_panel {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan down'],
      div[title^='Pan right'],
      div[title^='Pan up'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.contact-us-page {
  .email-artist-title {
    font-family: $ano-bold_regular-font;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    width: 22.5%;
    margin-right: 2%;
  }
}

.toolbar {
  .product--full {
    .product__sticky-wrapper {
      top: 132px;
    }
  }
}

#site-header {
  .site-email-signup {
    .site-email-signup__contents {
      .intro {
        width: 100%;
      }
      .picker-label {
        width: 100%;
      }
    }
  }
}

.site-container {
  .page--spp__custom_palette.MAC_6_PRO_LONGWEAR_PALETTE {
    .product__detail-image {
      display: block;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: url('/media/export/cms/products/custom_palettes/mac_sku_888239_640x600_0.jpg')
        no-repeat;
      width: 640px; /* Width of new image */
      height: 600px; /* Height of new image */
      padding-left: 640px; /* Equal to width of new image */
    }
  }
  .page--spp__product {
    .prod_inv_status-3 {
      .product__detail {
        .product__footer {
          .product__inventory-status {
            .coming-soon {
              display: block;
            }
          }
        }
      }
    }
    .product-full {
      &__shade-info {
        margin-bottom: 20px;
      }
      &__shades {
        padding-top: 15px;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .contact-us-page {
    .contact-nav {
      li {
        width: 31.5%;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body.fixedpos {
    width: 100%;
  }
}

.prod_inv_status-2 {
  .product__footer {
    .product__inventory-status {
      .temp-out-of-stock {
        display: block;
      }
    }
  }
}

.prod_inv_status-3 {
  .product__footer {
    .product__add-to-bag {
      &.coming-soon {
        display: none !important;
      }
    }
  }
}

.grid--mpp__item {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        .temp-outof-stock_text {
          margin-top: 35px;
          position: absolute;
          right: 0;
        }
        a.notify_me {
          margin: 0;
        }
        .notify-status {
          display: none;
        }
        .coming-soon_text {
          display: block;
          position: absolute;
          right: 0;
          margin-top: 10px;
        }
      }
    }
  }
}

.page--spp__product {
  .product--not-shoppable {
    .product__footer {
      .product__inventory-status {
        .coming-soon_text {
          position: relative;
          font-size: 18px;
        }
      }
    }
  }
  .product__inventory-status {
    .js-inv-status-3 {
      .notify-status {
        display: none;
      }
    }
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  height: 190px;
  margin: 17px 7px 5px 7px;
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 18px;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
}

.field {
  .email_input {
    float: left;
    width: 75%;
  }
  .align-r {
    float: left;
    margin-left: 10px;
    .input-btn {
      height: 58px;
    }
  }
  .terms_condition_cont {
    float: right;
    margin: 10px 5px 0 0;
  }
}

.site-footer--top {
  .grid-container--6 .grid--item {
    &:nth-of-type(6n + 2) {
      width: 19.66667%;
      margin-left: 19.66667%;
    }
    &:nth-of-type(6n + 3) {
      width: 19.66667%;
      margin-left: 39.33333%;
    }
    &:nth-of-type(6n + 4) {
      width: 19.66667%;
      margin-left: 59%;
    }
    &:nth-of-type(6n + 5) {
      width: 19.66667%;
      margin-left: 78.66667%;
    }
  }
}

.site-footer--bottom {
  .at-h6__sitefooter {
    display: none;
  }
  .block--footer-menu {
    padding-left: 8px;
  }
}

input[type='text'].error {
  border: 1px red solid !important;
}

.error {
  color: $color--error;
}

#shipping-panel {
  .edit-controls {
    .shipping-panel__title a {
      width: 228px;
    }
  }
}

#sign-in-panel {
  #checkout_signin {
    #checkout_signin-submit {
      width: 228px;
    }
  }
}

#sign-in-panel {
  #checkout_signin_new_user {
    #checkout_signin_new_user-submit {
      width: 228px;
    }
  }
}

.site-header {
  .site-header__prefix {
    .site-logo {
      .site-logo__image {
        background-size: auto;
      }
    }
  }
  .site-header__tools {
    .site-email-signup__contents-header {
      line-height: 20px;
      padding: 10px 15px 5px 15px;
    }
    .site-email-signup__contents-form {
      .form-item {
        margin: 0 0 5px 0;
      }
      .picker-label p {
        margin: 0 0 5px 0;
      }
    }
  }
}

.prod_inv_status-2 {
  .product__bag-bar {
    .temp-out-of-stock {
      display: block;
    }
  }
}

.page--spp__product {
  .prod_inv_status-2 {
    .temp-out-of-stock {
      &.display__notify-me {
        margin: 0 0 65px 0;
      }
    }
  }
}

.product__sticky-wrapper {
  .product__inv-status-item {
    &.temp-out-of-stock {
      background: black;
      font-family: $ano-bold_regular-font;
      font-size: 15px;
      text-decoration: none;
      a {
        &.notify_me {
          background-color: black;
          color: white;
          border-bottom: none;
        }
      }
      .temp-out-of-stock__text {
        display: none;
      }
    }
  }
}

.site-container {
  .page--spp__product {
    .product__price {
      width: 100%;
    }
    .product__price-installment {
      position: static;
    }
  }
}

.site-footer {
  .afterpay-content,
  .afterpay-content-mb {
    display: none;
  }
}

.foundation-finder {
  .product {
    &--full {
      .product {
        &__footer {
          .product__price {
            &-installment {
              position: initial;
              img {
                width: auto;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1023px) {
  .product__footer {
    .product_content_fav_mobile {
      display: block;
      float: left;
      width: 100%;
    }
  }
}

#csr_header_holder {
  padding-left: 0px;
}

.invis-search {
  &__container {
    .invis-form {
      &__container {
        width: 80%;
        float: #{$ldirection};
        text-align: #{$ldirection};
        @include breakpoint($bp--xlarger-up) {
          width: 60%;
        }
        @include breakpoint($bp--medium) {
          width: auto;
          float: none;
        }
        .search__row {
          width: 15%;
          margin-top: 0;
          margin-left: 5px;
          overflow: hidden;
        }
      }
      &__input--zip {
        width: 21%;
        margin-#{$rdirection}: 0;
        max-width: 21%;
        min-width: 21%;
        margin-top: 5px;
        @include swap_direction(padding, 22px 12px 0 12px);
        @include breakpoint($bp--xlarger-up) {
          width: 48%;
        }
        &::-webkit-input-placeholder {
          font-size: 10px;
        }
      }
      &__geo-container {
        @include breakpoint($bp--xlarger-up) {
          width: 33%;
          display: block;
        }
        .geo_search {
          padding: 0 10px;
          @include breakpoint($bp--xlarger-up) {
            width: 100%;
          }
          .icon--geolocation {
            margin-#{$rdirection}: 10px;
            font-size: 25px;
          }
        }
      }
      &__select--radius {
        width: 20%;
        min-width: 20%;
        &::-ms-value {
          background: transparent;
          color: inherit;
        }
        margin-#{$rdirection}: 0;
        padding-#{$rdirection}: 0;
        margin-top: 5px;
        @include breakpoint($bp--xlarger-up) {
          float: none;
        }
        @include breakpoint($bp--xlarger-up) {
          width: 30%;
        }
      }
      &__btn--submit {
        width: 13%;
        display: inline-block;
        margin-top: 5px;
        overflow: hidden;
        @include breakpoint($bp--xlarger-up) {
          width: auto;
          margin-top: 0;
        }
        @include breakpoint($bp--medium) {
          width: 30%;
        }
      }
      &__or-text {
        margin-top: 25px;
        margin-right: 5px;
        margin-left: 5px;
        @include breakpoint($bp--xlarger-up) {
          display: block;
          text-align: center;
          margin: 10px 0;
          font-size: 13px;
        }
      }
    }
    .invis-results {
      &__messages {
        width: 100%;
        float: #{$ldirection};
        text-align: #{$ldirection};
        @include breakpoint($bp--medium) {
          padding: 0;
        }
      }
    }
    .invis-sku {
      &__container {
        margin: 0;
        width: 20%;
        .invis-sku {
          &__image {
            width: 17%;
          }
          &__name {
            width: 75%;
          }
        }
      }
    }
    .gm-style {
      &-iw-t {
        &::after {
          top: 12px;
        }
      }
      &-iw-d {
        color: $white;
      }
    }
  }
  &__row {
    width: 100%;
  }
}

.invis--app {
  &__container {
    .invis-start {
      &__panel {
        .icon--geolocation {
          display: none;
        }
      }
    }
  }
}

.invis-content {
  margin-top: 20px;
}

.product-offer {
  &__edd {
    padding-bottom: 10px;
  }
}

.footer-menu {
  &--pc {
    .language {
      display: none;
    }
  }
}

.prod_inv_status-7 {
  .product__footer {
    .product__inventory-status {
      .sold-out {
        display: block;
      }
    }
  }
}
