.site-container {
  .checkout__content {
    #top-viewcart-buttons {
      display: none;
    }
    .guarantee-panel {
      border-bottom: none;
    }
    #sign-in-paypal-panel {
      border-bottom: none !important;
    }
    .shipping-panel {
      .column {
        &:nth-of-type(2n + 1) {
          width: 50%;
          float: left;
          clear: none;
          padding-right: 1em;
        }
        &:nth-of-type(2n + 2) {
          width: 50%;
          float: right;
          margin-left: 0;
          margin-right: 0;
          clear: none;
          padding-right: 0;
        }
        .gift-options {
          word-wrap: break-word;
        }
      }
    }
    .address-form,
    .billing-address-from-fields {
      .form-item.title {
        label {
          display: inline-block;
        }
        label[alt='* Title'] {
          display: inherit;
          margin-bottom: 5px;
        }
      }
    }
    .billing-address-form,
    .default-shipping,
    .edit-billing-radio,
    .edit-shipping-radio {
      label {
        display: inline-block;
      }
    }
    .billing-address-from-fields {
      .form-item.title {
        label {
          display: inline-block;
        }
      }
    }
    .product__desc {
      .product__desc-info.loading {
        width: 100%;
        float: none;
        padding-right: 0;
        margin: 0;
        text-transform: lowercase;
      }
    }
    .city-state-zip {
      .postal-code {
        width: 25%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none;
        padding-right: 0;
        input[type='text'] {
          width: 100%;
          margin-bottom: 0px;
        }
      }
      .state {
        select {
          height: 58px;
          text-decoration: none;
        }
        .select2-choice {
          text-decoration: none;
        }
      }
    }
    .form-item.title {
      width: 100% !important;
      .picker {
        display: inline-block;
        label {
          display: inline-block;
          &:first-child {
            display: none;
          }
        }
      }
    }
    .qas {
      .qas_container {
        width: 100%;
        padding-right: 0;
        .address_label {
          display: block;
        }
      }
    }
    .default-shipping {
      margin-bottom: 0px;
      label {
        display: inline-block;
      }
    }
    #bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        .content {
          width: 100%;
          .continue-buttons {
            width: 100% !important;
            padding-right: 10%;
            .btn.continue-checkout {
              border-width: 1px;
              border-color: #000;
            }
          }
        }
      }
    }
    #continue-btn {
      input[type='submit'] {
        line-height: 60px;
        border: none;
      }
    }
    .cart-header__title--total {
      @include layout($grid--12, 1em) {
        width: 18.33333%;
      }
    }
    .replen_notice {
      display: none;
    }
  }
  #confirmation-page {
    .checkout-header {
      .checkout-header__title {
        width: 100% !important;
        text-align: center;
        right: 0;
        padding-right: 0;
      }
    }
    #registration-panel {
      .messages-container {
        float: none;
        display: block;
      }
      .how-we-will-use-container,
      .checkout-registration__show-password {
        display: none;
      }
    }
  }
  .checkout {
    .right {
      .guarantee-panel {
        display: none;
      }
      .order-summary__shipping-content {
        .form-item.ship-method {
          width: 100%;
        }
      }
    }
    .sign-in-panel {
      border-bottom: 1px solid $color--gray--lightest;
      .messages-container {
        padding: 0;
        background-color: transparent;
        border-width: 0;
        .single-message {
          color: $color--error;
          text-align: left;
        }
      }
    }
    &.shipping_content {
      .checkout__content {
        padding-left: 0px;
      }
    }
    .form-item.ship-method {
      text-align: center;
      width: 90%;
      margin-bottom: 0px;
      .select2-choice {
        height: 0px;
        line-height: 30px;
        .select2-chosen {
          margin: 0px;
          width: 87%;
        }
      }
    }
    select::-ms-expand {
      display: none;
    }
    #continue-btn {
      clear: both;
      input.form-submit {
        width: 228px;
      }
    }
  }
  .checkout__sidebar {
    .need-help-panel {
      .text {
        color: $color--full-white;
        line-height: 1.5;
      }
    }
    select::-ms-expand {
      display: none;
    }
  }
  .checkout-header {
    #top-viewcart-buttons {
      width: 100%;
      border-top: 1px solid $color--gray--lightest;
      display: none;
      .viewcart-buttons-panel {
        padding-top: 1em;
        .content {
          width: 100%;
          .continue-buttons {
            margin-top: 10px;
            padding-right: 0;
            text-align: center;
            .btn.continue-checkout {
              border-width: 1px;
              border-color: #000;
            }
          }
        }
      }
    }
    .checkout-header__title {
      width: 100%;
      text-align: center;
      padding-right: 0;
      right: 0;
    }
  }
}

.shipping-panel {
  .edit-shipping-radio,
  .edit-billing-radio {
    .picker {
      display: inline-block;
      label {
        display: inline-block;
      }
    }
  }
  .gift-options {
    .picker-checkbox {
      display: block;
    }
    input[type='text'],
    textarea {
      &[data-error] {
        border-color: $color--error;
      }
    }
  }
}

.phones,
.name-fields {
  .first-name,
  .phone-1 {
    input[type='text'] {
      width: 100%;
    }
  }
}

.checkout {
  &.confirm {
    .account-nav {
      display: none;
    }
    .site-my-mac {
      display: none;
    }
  }
  .ui-widget-content {
    overflow-y: auto;
    height: 11.4em;
    font-family: tstar_mono_roundregular, Helvetica, Arial, sans-serif;
    top: 486px;
  }
  .recommended-product-items {
    .description {
      padding-bottom: 50px;
      .info {
        height: auto;
        margin-bottom: 30px;
        .formatted_price {
          position: relative;
          bottom: 0;
          margin-bottom: 10px;
        }
      }
    }
    .addtobag {
      position: absolute;
      bottom: 0;
      input[type='submit'] {
        line-height: 60px;
        border: none;
      }
    }
    .text--bold.price-range {
      position: relative;
      bottom: 65px;
      @if $fonts_update == true {
        @include h6();
      } @else {
        font-family: $ano-bold_regular-font;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 18px;
      }
    }
  }
  .ui-menu-item {
    a.ui-corner-all {
      padding-left: 12px;
      border: none !important;
      display: block;
    }
  }
  .panel select {
    margin-bottom: 0px;
  }
  .ui-corner-all {
    -webkit-border-radius: 0px;
    border-radius: 0px;
  }
  #shipping-panel,
  .checkout-registration {
    input[type='text'] + label:before,
    input[type='tel'] + label:before,
    input[name='PASSWORD2'] + label:before {
      content: attr(data-required) attr(placeholder);
    }
  }
  .gift-cards {
    input[type='password'] + label:before {
      content: attr(data-required) attr(placeholder);
    }
  }
  .checkout-registration {
    .checkout-password-label {
      display: none;
    }
    input[name='PASSWORD'].js-label-mode + label:before {
      content: attr(alt);
    }
  }
  .checkout-header {
    .continue-shopping {
      display: inline;
    }
  }
  .panel {
    .city-state-zip {
      .form-item.state {
        padding-left: 10px;
      }
    }
    .billing-address {
      .address1 {
        float: none;
        width: 100%;
        padding-right: 0px;
      }
      .address2 {
        width: 100%;
      }
    }
  }
  .sample-select-button {
    display: none;
  }
  .product-shade-name {
    display: inline-block;
    margin: 5px 0;
  }
  &.active-panel-shipping {
    .site-header-formatter {
      z-index: 100;
    }
  }
}

.phones,
.city-state-zip {
  .phone-1,
  .phone-2,
  .postal-code {
    input[type='text'].error {
      border-color: $color--error;
    }
  }
}

.section-email-promotions {
  .pc_email_promo_container {
    input[type='text'].error {
      border-color: $color--error;
    }
  }
}

.lpPoweredBy {
  display: none;
}

#shipping-panel {
  &.finished {
    #shipping-address-display,
    #gift-options,
    #billing-address-display {
      float: left;
      width: 33%;
      padding-right: 2%;
      word-wrap: break-word;
    }
  }
}

#offer_code {
  .offer-code__submit {
    input[type='submit'] {
      line-height: 60px;
    }
  }
}

.select2-drop {
  .select2-results {
    .select2-result-label {
      span.select2-match {
        padding-left: 15px;
      }
    }
  }
}

.checkout.panel {
  .panel {
    .new-address {
      input:required[data-error],
      select:required[data-error],
      input:required.error,
      select:required.error {
        border-color: red !important;
      }
    }
  }
  .panel.finished {
    display: block;
  }
  .signin-panel {
    header {
      display: block;
    }
  }
}

.adpl {
  input[type='email'].error,
  input[type='tel'].error,
  input[type='password'].error,
  input[type='text'].error {
    color: $color--error !important;
    border-color: $color--error !important;
  }
  input[type='email'] + label:before,
  input[type='tel'] + label:before,
  input[type='password'] + label:before,
  input[type='text'] + label:before {
    content: attr(data-required) attr(placeholder);
  }
}

.replenishment__overlay--header {
  font-size: 2rem;
  padding-bottom: 5px;
}

.checkout__sidebar {
  #links-panel {
    margin-top: 1em;
  }
  .tab-bar {
    display: none;
  }
}

.checkout__index-content {
  .cart_item__error--hazmat {
    margin-top: 170px;
  }
}

section#promo-panel {
  display: none;
}

.checkout .cart-item__qty-label,
.checkout .cart-item__size-label {
  display: none;
}

.active-panel-dramming,
.active-panel-stickers {
  .pg_wrapper {
    margin: 0 auto;
    max-width: 1281px;
    position: relative;
  }
  header {
    border-bottom: 1px solid $color--gray--lightest;
    text-align: center;
    h2.samples-panel__title {
      width: 100%;
      padding: 0.5em 0;
      &.stickers_header {
        width: 100%;
        display: inline-block;
      }
    }
    .stickers {
      width: 60%;
      display: inline-block;
      .pink {
        color: $color--pink-tier1;
      }
      .blue {
        color: $color--blue--lighter;
      }
    }
  }
  .samples-panel__continue-checkout,
  .stickers_next,
  .stickers_back {
    width: 20%;
    float: right;
    &.mobile_hidden {
      display: inline-block !important;
    }
    a.continue-checkout,
    a.stickers {
      float: right;
      margin: 10px;
      width: 200px;
      position: absolute;
      top: 25px;
      @include breakpoint($bp--xlarge-down) {
        right: 0;
      }
    }
  }
  .stickers_back {
    float: left;
  }
  .product.clearfix {
    width: 9%;
    display: inline-block;
    margin: 5px 0;
    &.non-selected {
      pointer-events: none;
    }
    .product__thumb {
      margin: 0 auto 10px 0;
      width: 100%;
      .view-larger {
        display: none;
      }
    }
    .product__name {
      margin-bottom: 5px;
    }
    .product-shade-number {
      font-family: Helvetica, Arial, sans-serif;
      font-size: 12px;
    }
    .product__desc {
      width: 100%;
      .sample-select-button {
        display: block;
        line-height: 30px;
        height: 30px;
        width: 60%;
        margin-left: 16%;
        padding: 0;
      }
    }
  }
}
