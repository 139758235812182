/**
 * @file au_pc.scss
 *
 * \brief Pull all parts of the AU-PC theme together into one file
 */

$appt_booking: true;

@import 'overrides/overrides';
$product-full-overview-height: 405px;

@import '../../mac_base/scss/mac-base';
@import 'sections/_colors';
@import 'sections/_gwp_pc';
@import 'sections/_all';
@import 'base/common';

// SMACSS/sections/account
@import 'sections/pc/account/account';
@import 'sections/account/account-payment';

// SMACSS/Components/checkout
@import 'sections/pc/checkout/checkout';
@import 'sections/checkout/checkout-payment';

// SMACSS/Section/all
@import 'sections/pc/all';
@import 'components/_site-header';
@import 'components/_sticky-footer';

// Social login
@import 'sections/social_login';

// Click and collect
@import 'sections/click_and_collect';

//Phone Order
@import 'components/phone_order';

// One Trust Cookie Styles.
@import '../../mac_base/scss/onetrust_cookie/cookie_settings_ot';

// Password strengthening
@import 'sections/account-password-strengthen';

// Order tracking
@import 'sections/customer-care/where-is-my-order';
@import 'sections/customer-care/contact-us';

// Book Appointment.
@import 'sections/appointment-booking';

// Mac-lover Loyalty Program
@import 'loyalty/loyalty_all';
