.site-container {
  .mtop {
    #form--errors {
      margin-top: 20px;
    }
  }
  .profile-page__content {
    .birthday_col {
      .form_birthday {
        width: 100%;
        label {
          display: block;
          margin-bottom: 15px;
        }
      }
      .gender_col {
        label {
          display: block;
        }
      }
      .select2-container {
        width: 25% !important;
        margin-right: 5%;
      }
    }
    .profile-info__fieldset {
      .field-container {
        .form_title_width {
          width: 100%;
          .picker {
            display: inline-block;
          }
          .picker-radio.picker {
            &.checked {
              .picker-handle {
                .picker-flag {
                  margin: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
  .account-order-history {
    .past-purchases {
      .past-purchases__item {
        .order-status {
          .order-status {
            margin-left: 0% !important;
          }
        }
      }
    }
  }
  .custom-grid__item {
    .text-header {
      width: 75%;
      border-right: 1px solid white;
    }
    .logo-img {
      top: 12%;
      width: 25%;
      position: relative;
      float: right;
      padding: 0 3%;
      text-align: center;
    }
    .logo-txt {
      text-align: center;
      color: white;
    }
  }
  .past-purchases-page__content {
    .past-purchases {
      .products {
        .product__footer {
          .product-item__unavailable-message {
            float: right;
            height: 40px;
            line-height: 40px;
            margin: 0;
          }
        }
      }
    }
  }
}

#address_form_container {
  margin-bottom: 12px;
  input[type='radio'] {
    -webkit-appearance: radio;
  }
  input[type='checkbox'] {
    -webkit-appearance: checkbox;
  }
  .address-form {
    .address-overlay-shipping {
      select.error,
      input[type='text'].error {
        border: 1px solid $color--error;
      }
      .address_label {
        display: block;
      }
      .address_title_label,
      .address_title_header {
        label {
          display: inline-block;
        }
      }
    }
    .default_ship_container label {
      display: inline-block;
    }
    .form_text {
      margin-bottom: 10px;
    }
  }
}

.sign-in-page {
  .pc_signin_container {
    .pc_signin_button {
      width: 50%;
      float: left;
      margin-right: -100%;
      clear: none;
      padding-right: 1em;
      clear: both;
    }
  }
  .error_messages {
    display: inline-block;
    margin-bottom: 5px;
  }
}

.account {
  .ui-widget-content {
    height: 12em;
    overflow-y: auto;
  }
}

.order-details-page {
  .cart-item {
    .cart-item__desc {
      margin-left: 25%;
    }
    .cart-item__price {
      margin-left: 50%;
    }
    .cart-item__qty {
      margin-left: 72%;
    }
  }
  .order-products {
    .virtual-bundling {
      .cart-item__desc {
        margin-#{$ldirection}: 17%;
      }
      .cart-product-kit-shades {
        padding: 0;
      }
      .kit-container {
        @include swap_direction(margin, 0 0 0 17%);
        @include swap_direction(padding, 90px 0 0 0);
        .cart-item {
          &__desc-info {
            @include swap_direction(margin, 0 0 20px 0);
            @include swap_direction(padding, 0 0 20px 0);
            border-bottom: 1px solid #{$color--black};
            &:last-child {
              border-bottom: none;
              margin: 0;
              padding: 0;
            }
          }
        }
        .swatch {
          border-radius: 50%;
          display: inline-block;
          height: 22px;
          position: relative;
          top: 6px;
          width: 22px;
        }
      }
    }
  }
  .product-header {
    .price {
      margin-left: 50%;
    }
    .quantity {
      margin-left: 72%;
    }
    .total {
      width: 11.33333%;
    }
  }
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.site-header {
  .site-my-mac {
    text-shadow: 0 0 0;
  }
}

.custom-palette__artist {
  .custom-palette__artist-footer {
    bottom: 28px;
  }
}

.product--not-shoppable.product--shaded {
  .product__footer {
    .product__inventory-status {
      .temp-outof-stock_text {
        right: 170px;
        margin-top: 10px;
      }
      .inactive {
        position: absolute;
        right: 0;
      }
    }
  }
}

.product__inventory-status {
  li.product__inv-status-item {
    padding: 0;
  }
}

.picker {
  label.error {
    color: #f14927;
  }
}

.order-products {
  .cart-item__thumb {
    img.cart-item__thumb-image {
      margin: 0 0 15px 0;
    }
  }
}

.site-header {
  .site-header__tools {
    .site-email-signup__contents-form {
      .form-item {
        .error {
          display: block;
        }
      }
    }
  }
}

.gift_message {
  word-wrap: break-word;
}

.replenish_details {
  #cboxLoadingOverlay,
  #cboxLoadingGraphic {
    position: relative;
  }
  .ui-widget-content,
  .ui-widget-header {
    .ui-state-hover {
      border: 0;
    }
  }
}

.replenishment-page {
  h2.recommended_products {
    font-size: 22px;
    display: inline-block;
    margin: 20px 0;
  }
}

.replenishment-detail-page {
  .products-list__list {
    .js-rep-details {
      display: none;
    }
  }
}
